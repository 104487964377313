// pages/Profile.js
import React, { useEffect, useState } from 'react';
import { getUserCharacters as characterServiceGetUser, deleteCharacter as characterServiceDelete } from '../services/characterService';
import { deleteUserAccount as authServiceDeleteUser, login as authLogin, updateUserPersona as authServiceUpdatePersona, getUserPersona as authServiceGetPersona, updateUserCredentials as authServiceUpdateCredentials } from '../services/authService'; // Import the new services
import { Container, Typography, Box, Grid, Card, CardContent, Button, CardMedia, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Snackbar, Accordion, AccordionSummary, AccordionDetails, Switch, FormControlLabel, Tabs, Tab } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../config.js';

const Profile = ({ darkMode, toggleDarkMode }) => {
  const [characters, setCharacters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [imageUrls, setImageUrls] = useState({});
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [characterToDelete, setCharacterToDelete] = useState(null);
  const [deleteAccountDialogOpen, setDeleteAccountDialogOpen] = useState(false);
  const [catchphrase, setCatchphrase] = useState('');
  const [userCatchphrase, setUserCatchphrase] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [userPersona, setUserPersona] = useState('');
  const [userPersonaName, setUserPersonaName] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [oldPassword, setOldPassword] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [changingEmail, setChangingEmail] = useState(false);
  const [changingPassword, setChangingPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCharacters = async () => {
      try {
        const response = await characterServiceGetUser();
        setCharacters(response.data);
      } catch (err) {
        setError('Failed to load characters. Please try again.' + err);
      } finally {
        setLoading(false);
      }
    };
    const fetchUserPersona = async () => {
      try {
        const response = await authServiceGetPersona();
        if (response.data.persona) {
          setUserPersona(response.data.persona.description || '');
          setUserPersonaName(response.data.persona.name || '');
          setEmail(response.data.email || '');
        }
      } catch (err) {
        setError('Failed to load user persona. Try logging out and logging back in.' + err);
      }
    };
    fetchCharacters();
    fetchUserPersona();
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      const urls = {};
      for (const character of characters) {
        if (character.imageUrl) {
          const imageUrl = await fetchImageUrl(character.imageUrl);
          urls[character._id] = imageUrl;
        }
      }
      setImageUrls(urls);
    };
    fetchImages();
  }, [characters]);

  const fetchImageUrl = async (imageUrl) => {
    try {
      const response = await fetch(`${API_URL}${imageUrl}`, {
        headers: {
          'ngrok-skip-browser-warning': 'any_value'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch image');
      }
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    } catch (err) {
      console.error('Error fetching image:', err);
      return null;
    }
  };

  const handleDeleteCharacter = async (id) => {
    try {
      await characterServiceDelete(id);
      setCharacters((prevCharacters) => prevCharacters.filter((character) => character._id !== id));
      setDeleteDialogOpen(false); // Close the dialog after deletion
    } catch (err) {
      setError('Failed to delete character. Please try again.');
    }
  };

  const handleChat = (id) => {
    navigate(`/chat/${id}`);
  };

  const handleEdit = (id) => {
    navigate(`/edit-character/${id}`);
  };

  const confirmDelete = (id) => {
    setCharacterToDelete(id);
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setCharacterToDelete(null);
  };

  const confirmDeleteAccount = () => {
    const randomCatchphrase = Math.random().toString(36).substring(7);
    setCatchphrase(randomCatchphrase);
    setDeleteAccountDialogOpen(true);
  };

  const closeDeleteAccountDialog = () => {
    setDeleteAccountDialogOpen(false);
    setCatchphrase('');
    setUserCatchphrase('');
    setEmail('');
    setPassword('');
  };

  const handleDeleteAccount = async (e) => {
    e.preventDefault();
    if (userCatchphrase !== catchphrase) {
      setError('Catchphrase does not match. Please try again.');
      return;
    }
    try {
      const response = await authLogin({ email, password });
      localStorage.setItem('token', response.data.token);
      await authServiceDeleteUser();
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      setSnackbarMessage('Account successfully deleted');
      setSnackbarOpen(true);
      navigate('/');
    } catch (err) {
      setError('Failed to delete account. Please try again.');
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSavePersona = async () => {
    try {
      await authServiceUpdatePersona({
        persona: {
          name: userPersonaName,
          description: userPersona
        }
      });
      setSnackbarMessage('Persona saved successfully!');
      setSnackbarOpen(true);
    } catch (err) {
      setError('Failed to save persona. Please try again.');
    }
  };

  const handleUpdateEmail = async (e) => {
    e.preventDefault();
    try {
      await authServiceUpdateCredentials({
        oldPassword,
        newEmail
      });
      setSnackbarMessage('Email updated successfully!');
      setSnackbarOpen(true);
      setChangingEmail(false);
    } catch (err) {
      setError('Failed to update email. Please try again.');
    }
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    try {
      await authServiceUpdateCredentials({
        oldPassword,
        newPassword
      });
      setSnackbarMessage('Password updated successfully!');
      setSnackbarOpen(true);
      setChangingPassword(false);
    } catch (err) {
      setError('Failed to update password. Please try again.');
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Container maxWidth="lg">
      <Box mt={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Your Profile
        </Typography>
        <Tabs value={tabValue} onChange={handleTabChange} variant="fullWidth">
          <Tab label="Characters" />
          <Tab label="AI Settings" />
          <Tab label="App Settings" />
          <Tab label="Account Settings" />
        </Tabs>
        {tabValue === 0 && (
          <Box mt={4}>
            <Grid container spacing={3}>
              {characters.map((character) => (
                <Grid item xs={12} sm={6} md={4} key={character._id}>
                  <Card>
                    {character.imageUrl && (
                      <CardMedia
                        component="img"
                        height="194"
                        image={imageUrls[character._id]}
                        alt={character.name}
                      />
                    )}
                    <CardContent>
                      <Typography variant="h5" component="div">
                        {character.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {character.description}
                      </Typography>
                      <Button variant="contained" color="primary" onClick={() => handleChat(character._id)} style={{ marginTop: '16px' }}>
                        Chat
                      </Button>
                      <Button variant="contained" color="secondary" onClick={() => handleEdit(character._id)} style={{ marginTop: '16px', marginLeft: '8px' }}>
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => confirmDelete(character._id)}
                        style={{ marginTop: '16px', marginLeft: '8px' }}
                      >
                        Delete
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
        {tabValue === 1 && (
          <Box mt={4}>
            <Typography variant="h5" component="h2" gutterBottom>
              Your Persona
            </Typography>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>What is this?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="p" component="p" gutterBottom>
                  Your User Persona is a description of a fictional character that you want to portray yourself as during your interactions with the bots. This can include details about your fictional character's personality, background, interests, and any other information that you think will enhance your interactions.
                </Typography>
                <Typography variant="p" component="p" gutterBottom>
                  Using a User Persona allows you to engage in more immersive and personalized conversations with the bots. It helps the bots to better understand your preferences and tailor their responses to create a more enjoyable and interactive experience.
                </Typography>
                <Typography variant="p" component="p" gutterBottom>
                  Feel free to make up anything you like! Your User Persona doesn't have to be about your IRL self; it can be about a fictional character you want to role-play as. Here are some ideas to get you started:
                </Typography>
                <Typography variant="p" component="p" gutterBottom>
                  <strong>General Info:</strong> Describe your name, gender, age, and appearance
                </Typography>
                <Typography variant="p" component="p" gutterBottom>
                  <strong>Personality Traits:</strong> Describe what kind of person your character is. Are they outgoing, shy, adventurous, or cautious?
                </Typography>
                <Typography variant="p" component="p" gutterBottom>
                  <strong>Background Story:</strong> Include details about your character's past and experiences. Where do they come from? What have they done in their life?
                </Typography>
                <Typography variant="p" component="p" gutterBottom>
                  <strong>Interests and Hobbies:</strong> What does your character enjoy doing? Do they have any special skills or talents?
                </Typography>
                <Typography variant="p" component="p" gutterBottom>
                  <strong>Goals and Aspirations:</strong> What drives your character? What are their dreams and ambitions?
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Box mt={4}>
              <TextField
                fullWidth
                label="Persona Name"
                value={userPersonaName}
                onChange={(e) => setUserPersonaName(e.target.value)}
                margin="normal"
                autoComplete="off"
              />
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Describe your persona"
                value={userPersona}
                onChange={(e) => setUserPersona(e.target.value)}
                margin="normal"
              />
              <Button variant="contained" color="primary" onClick={handleSavePersona} style={{ marginTop: '16px' }}>
                Save Persona
              </Button>
            </Box>
          </Box>
        )}
        {tabValue === 2 && (
          <Box mt={4}>
            <Typography variant="h5" component="h2" gutterBottom>
              App Settings
            </Typography>
            <FormControlLabel
              control={<Switch checked={darkMode} onChange={toggleDarkMode} color="primary" />}
              label="Dark Mode"
            />
          </Box>
        )}
        {tabValue === 3 && (
          <Box mt={4}>
            <Typography variant="h5" component="h2" gutterBottom>
              Account Settings
            </Typography>
            <Box mt={4}>
              <Typography variant="h6" component="h3" gutterBottom>
                Update Email
              </Typography>
              <Box display="flex" alignItems="center">
                <TextField
                  disabled
                  fullWidth
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  margin="normal"
                />
                <Button variant="contained" color="primary" onClick={() => setChangingEmail(true)} style={{ marginLeft: '16px' }}>
                  Change
                </Button>
              </Box>
              {changingEmail && (
                <form onSubmit={handleUpdateEmail}>
                  <TextField
                    fullWidth
                    label="Old Password"
                    type="password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    margin="normal"
                    required
                  />
                  <TextField
                    fullWidth
                    label="New Email"
                    type="email"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    margin="normal"
                    required
                  />
                  <Button variant="contained" color="primary" type="submit" style={{ marginTop: '16px' }}>
                    Update Email
                  </Button>
                </form>
              )}
            </Box>
            <Box mt={4}>
              <Typography variant="h6" component="h3" gutterBottom>
                Update Password
              </Typography>
              <Box display="flex" alignItems="center">
                <TextField
                  disabled
                  fullWidth
                  label="Password"
                  type="password"
                  value="********"
                  margin="normal"
                />
                <Button variant="contained" color="primary" onClick={() => setChangingPassword(true)} style={{ marginLeft: '16px' }}>
                  Change
                </Button>
              </Box>
              {changingPassword && (
                <form onSubmit={handleUpdatePassword}>
                  <TextField
                    fullWidth
                    label="Old Password"
                    type="password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    margin="normal"
                    required
                  />
                  <TextField
                    fullWidth
                    label="New Password"
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    margin="normal"
                    required
                  />
                  <Button variant="contained" color="primary" type="submit" style={{ marginTop: '16px' }}>
                    Update Password
                  </Button>
                </form>
              )}
            </Box>
            <Button
              variant="contained"
              color="error"
              onClick={confirmDeleteAccount}
              style={{ marginTop: '16px', marginLeft: '8px', display: 'block', margin: '16px auto' }}
            >
              Delete Account
            </Button>
          </Box>
        )}
      </Box>
      {/* Delete Character Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={closeDeleteDialog}>
        <DialogTitle>{"Delete Character"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this character? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDeleteCharacter(characterToDelete)} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* Delete Account Confirmation Dialog */}
      <Dialog open={deleteAccountDialogOpen} onClose={closeDeleteAccountDialog}>
        <DialogTitle>{"Delete Account"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please type the following catchphrase to confirm deletion: {catchphrase}
          </DialogContentText>
          <TextField
            fullWidth
            label="Catchphrase"
            value={userCatchphrase}
            onChange={(e) => setUserCatchphrase(e.target.value)}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="normal"
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteAccountDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteAccount} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* Snackbar for account deletion confirmation */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </Container>
  );
};

export default Profile;