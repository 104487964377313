// pages/Conversations.js
import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { getUserConversations, deleteConversation } from '../services/conversationService';


const Conversations = () => {
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const response = await getUserConversations();
        setConversations(response.data);
      } catch (err) {
        setError('Failed to load conversations. Please try again.');
      } finally {
        setLoading(false);
      }
    };
    fetchConversations();
  }, []);

  const handleDeleteConversation = async (id) => {
    try {
      await deleteConversation(id);
      setConversations(conversations.filter(conversation => conversation._id !== id));
    } catch (err) {
      setError('Failed to delete conversation. Please try again.');
    }
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Container maxWidth="md">
      <Box mt={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Your Conversations
        </Typography>
        {conversations.length === 0 ? (
          <Typography>No conversations found.</Typography>
        ) : (
          conversations.map(conversation => (
            <Box key={conversation._id} mb={2}>
              <Typography>{conversation.character.name}</Typography>
              <Button variant="contained" color="secondary" onClick={() => handleDeleteConversation(conversation._id)}>
                Delete
              </Button>
            </Box>
          ))
        )}
      </Box>
    </Container>
  );
};

export default Conversations;