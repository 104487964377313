//components/Sidebar.js

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemText, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: 240,
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.background.paper,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    color: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.text.secondary,
  },
  menuButton: {
    position: 'fixed',
    top: theme.spacing(2), // Adjust the top position as needed
    left: theme.spacing(2), // Adjust the left position as needed
    zIndex: theme.zIndex.drawer + 1, // Ensure the button is above the drawer
  },
  listItemText: {
    color: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.text.secondary,
  },
}));

const Sidebar = ({ darkMode }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem('token') !== null;

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const toggleDrawer = (isOpen) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(isOpen);
  };

  const list = () => (
    <div className={classes.drawerPaper} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <div className={classes.drawerHeader}>
        <Typography variant="h6" noWrap>
          Fichat
        </Typography>
        <IconButton onClick={toggleDrawer(false)}>
          <CloseIcon />
        </IconButton>
      </div>
      <List>
        <ListItem button component={Link} to="/">
          <ListItemText primary="Home" className={classes.listItemText} />
        </ListItem>
        <ListItem button component={Link} to="/explore">
          <ListItemText primary="Explore" className={classes.listItemText} />
        </ListItem>
        {isAuthenticated && (
          <>
            <ListItem button component={Link} to="/create-character">
              <ListItemText primary="Create Character" className={classes.listItemText} />
            </ListItem>
            <ListItem button component={Link} to="/profile">
              <ListItemText primary="Profile" className={classes.listItemText} />
            </ListItem>
            <ListItem button component={Link} onClick={handleLogout}>
              <ListItemText primary="Log Out" className={classes.listItemText} />
            </ListItem>
          </>
        )}
        {!isAuthenticated && (
          <>
            <ListItem button component={Link} to="/login">
              <ListItemText primary="Login" className={classes.listItemText} />
            </ListItem>
            <ListItem button component={Link} to="/signup">
              <ListItemText primary="Signup" className={classes.listItemText} />
            </ListItem>
          </>
        )}
      </List>
    </div>
  );

  return (
    <>
      <IconButton
        onClick={toggleDrawer(true)}
        edge="start"
        color="inherit"
        aria-label="menu"
        className={classes.menuButton}
        sx={{
          padding: '2px',
          position: 'fixed',
          display: open ? 'none' : 'block', // Hide the button when the sidebar is open
        }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </>
  );
};

export default Sidebar;