//pages/CreateCharacter.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createCharacter as characterServiceCreate } from '../services/characterService';
import { TextField, Button, Container, Typography, Box, Select, MenuItem, FormControl, InputLabel, ThemeProvider, CssBaseline } from '@mui/material';
import { API_URL } from '../config.js';
import { lightTheme, darkTheme } from '../theme';

const CreateCharacter = ({ darkMode }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [persona, setPersona] = useState('');
  const [scenario, setScenario] = useState('');
  const [chatCounts, setChatCounts] = useState(0);
  const [firstMessage, setFirstMessage] = useState('');
  const [tags, setTags] = useState([]);
  const [visibility, setVisibility] = useState('public');
  const [image, setImage] = useState(null);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (visibility === 'public' && !image) {
      setError('You must upload an image to make the character public.');
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('persona', persona);
    formData.append('scenario', scenario);
    formData.append('firstMessage', firstMessage);
    formData.append('tags', JSON.stringify(tags)); // Convert tags to JSON string
    formData.append('visibility', visibility);
    formData.append('chatCount', 0);
    if (image) {
      formData.append('image', image);
    }

    const userId = localStorage.getItem('userId');
    if (userId) {
      formData.append('creator', userId);
    } else {
      setError('User ID not found in local storage. Try logging out and logging back in again');
      return;
    }

    try {
      await characterServiceCreate(formData);
      navigate('/explore');
    } catch (err) {
      setError('Failed to create character. Please try again.');
    }
  };

  const handleTagChange = (e) => {
    setTags(e.target.value);
  };

  return (
  <ThemeProvider theme={darkMode ? darkTheme : lightTheme}> <CssBaseline />
    <Container maxWidth="md">
      <Box mt={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Create Character
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
            required
            autoComplete="off"
          />
          <TextField
            fullWidth
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            margin="normal"
            multiline
            rows={4}
            required
            autoComplete="off"
          />
          <TextField
            fullWidth
            label="Persona"
            value={persona}
            onChange={(e) => setPersona(e.target.value)}
            margin="normal"
            multiline
            rows={4}
            required
            autoComplete="off"
          />
          <TextField
            fullWidth
            label="Scenario"
            value={scenario}
            onChange={(e) => setScenario(e.target.value)}
            margin="normal"
            multiline
            rows={4}
            required
            autoComplete="off"
          />
          <TextField
            fullWidth
            label="First Message"
            value={firstMessage}
            onChange={(e) => setFirstMessage(e.target.value)}
            margin="normal"
            required
            autoComplete="off"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Tags</InputLabel>
            <Select
              multiple
              value={tags}
              onChange={handleTagChange}
              label="Tags"
              renderValue={(selected) => selected.join(', ')}
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Original">Original</MenuItem>
              <MenuItem value="Fandom Character">Fandom Character</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Visibility</InputLabel>
            <Select
              value={visibility}
              onChange={(e) => setVisibility(e.target.value)}
              label="Visibility"
            >
              <MenuItem value="public">Public</MenuItem>
              <MenuItem value="private">Private</MenuItem>
            </Select>
          </FormControl>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setImage(e.target.files[0])}
            style={{ display: 'block', margin: '16px 0' }}
          />
          {error && <Typography color="error">{error}</Typography>}
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Create Character
          </Button>
        </form>
      </Box>
    </Container>
    </ThemeProvider>
  );
};

export default CreateCharacter;