//services/conversationService.js

import axios from 'axios';
import { API_URL } from '../config.js';

const headers = {
  'ngrok-skip-browser-warning': 'any_value'
};

export const getUserConversations = () => {
  const token = localStorage.getItem('token');
  return axios.get(`${API_URL}/conversations`, {
    headers: { ...headers, Authorization: token },
  });
};

export const getConversation = (characterId) => {
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');
  return axios.get(`${API_URL}/conversations/character/${characterId}/user/${userId}`, {
    headers: { ...headers, Authorization: token },
  });
};

export const saveConversation = (conversationData) => {
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');
  if (!userId) {
    throw new Error('User ID not found in local storage');
  }
  conversationData.userId = userId; // Ensure userId is included
  return axios.post(`${API_URL}/conversations`, conversationData, {
    headers: { ...headers, Authorization: token },
  });
};

export const updateConversation = (conversationData) => {
  const token = localStorage.getItem('token');
  return axios.put(`${API_URL}/conversations/${conversationData.characterId}/user/${conversationData.userId}`, conversationData, {
    headers: { ...headers, Authorization: token },
  });
};

export const deleteConversation = (characterId) => {
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');
  return axios.delete(`${API_URL}/conversations/${characterId}/user/${userId}`, {
    headers: { ...headers, Authorization: token },
  });
};