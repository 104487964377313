// rehypeInlineQuote.js
import { visit } from 'unist-util-visit';

export default function rehypeInlineQuote() {
  return function transformer(tree) {
    visit(tree, 'text', (node, index, parent) => {
      if (parent.tagName === 'p') {
        const text = node.value;
        const parts = text.split(/(".*?")/);
        const savedDarkMode = localStorage.getItem('darkMode') === 'true';
        const nodes = parts.map((part, i) => {
          if (part.startsWith('"') && part.endsWith('"')) {
            return {
              type: 'element',
              tagName: 'span',
              properties: {
                style: { color: savedDarkMode ? '#D7751C' : '#FFFFFF' }, // Change color based on darkMode
                //style: { color: '#D7751C' }, // Change color based on darkMode
              },
              children: [{ type: 'text', value: part }],
            };
          }
          return {
            type: 'text',
            value: part,
          };
        });
        parent.children.splice(index, 1, ...nodes);
      }
    });
  };
}