// pages/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login as authLogin } from '../services/authService';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import ForgotPasswordDialog from '../components/ForgotPasswordDialog';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await authLogin({ email: email.toLowerCase(), password });
      // Handle successful login, e.g., store token and userId in local storage
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('userId', response.data.userId); // Store userId in local storage
      navigate('/');
    } catch (err) {
      setError('Invalid email or password');
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Container maxWidth="xs">
      <Box mt={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Login
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="normal"
            required
          />
          {error && <Typography color="error">{error}</Typography>}
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Login
          </Button>
          <Button color="secondary" fullWidth onClick={handleOpenDialog}>
            Forgot Password?
          </Button>
        </form>
      </Box>
      <ForgotPasswordDialog open={openDialog} onClose={handleCloseDialog} />
    </Container>
  );
};

export default Login;