// src/WithAds.js
import React from 'react';
import { Helmet } from 'react-helmet';

const WithAds = (WrappedComponent) => {
  return (props) => (
    <>
      <Helmet>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5234640600425235" crossOrigin="anonymous"></script>
      </Helmet>
      <WrappedComponent {...props} />
    </>
  );
};

export default WithAds;