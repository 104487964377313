// pages/EditCharacter.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getCharacter as characterServiceGet, updateCharacter as characterServiceUpdate } from '../services/characterService';
import { TextField, Button, Container, Typography, Box, Select, MenuItem, FormControl, InputLabel, ThemeProvider, CssBaseline } from '@mui/material';
import { API_URL } from '../config.js';
import { lightTheme, darkTheme } from '../theme';


const EditCharacter = ({ darkMode }) => {
  const { id } = useParams();
  const [character, setCharacter] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [persona, setPersona] = useState('');
  const [scenario, setScenario] = useState('');
  const [chatCounts, setChatCounts] = useState(0);
  const [firstMessage, setFirstMessage] = useState('');
  const [tags, setTags] = useState([]);
  const [visibility, setVisibility] = useState('public');
  const [image, setImage] = useState(null);
  const [currentImageUrl, setCurrentImageUrl] = useState('');
  const [currentImageData, setCurrentImageData] = useState(null);
  const [showImageInput, setShowImageInput] = useState(false);
  const [error, setError] = useState('');
  const [imageChanged, setImageChanged] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCharacter = async () => {
      try {
        const response = await characterServiceGet(id);
        const characterData = response.data;
        setCharacter(characterData);
        setName(characterData.name);
        setDescription(characterData.description);
        setPersona(characterData.persona);
        setScenario(characterData.scenario);
        setFirstMessage(characterData.firstMessage);
        setChatCounts(characterData.chatCount);
        let tagsArray;
        if (Array.isArray(characterData.tags)) {
          tagsArray = characterData.tags.map(tag => tag.replace(/^"|"$/g, ''));
        } else {
          tagsArray = [characterData.tags.replace(/^"|"$/g, '')];
        }
        setTags(tagsArray);
        setVisibility(characterData.visibility);
        setCurrentImageUrl(characterData.imageUrl);
        const imageResponse = await fetch(`${API_URL}${characterData.imageUrl}`, {
          headers: {
            'ngrok-skip-browser-warning': 'true'
          }
        });
        const imageBlob = await imageResponse.blob();
        setCurrentImageData(imageBlob);
      } catch (err) {
        setError('Error, image not found.');
      }
    };
    fetchCharacter();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (visibility === 'public' && !image && !currentImageData) {
      setError('You must upload an image to make the character public.');
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('persona', persona);
    formData.append('scenario', scenario);
    formData.append('firstMessage', firstMessage);
    formData.append('tags', JSON.stringify(tags));
    formData.append('visibility', visibility);
    formData.append('imageChanged', imageChanged);
    formData.append('chatCount', chatCounts);
    if (imageChanged) {
      formData.append('image', image);
    } else if (currentImageData) {
      formData.append('image', currentImageData, currentImageUrl);
    }

    const userId = localStorage.getItem('userId');
    if (userId) {
      formData.append('creator', userId);
    } else {
      setError('User ID not found in local storage');
      return;
    }

    try {
      await characterServiceUpdate(id, formData);
      navigate('/profile');
    } catch (err) {
      setError('Failed to update character. Please try again.');
    }
  };

  const handleTagChange = (e) => {
    setTags(e.target.value);
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    setImageChanged(true);
  };

  const handleDeleteImage = () => {
    setImage(null);
    setImageChanged(true);
  };

  const handleCancel = () => {
    navigate('/profile');
  };

  if (!character) {
    return <Typography>Loading...</Typography>;
  }

  return (
  <ThemeProvider theme={darkMode ? darkTheme : lightTheme}> <CssBaseline />
    <Container maxWidth="md">
      <Box mt={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Edit Character
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
            required
            autoComplete="off"
          />
          <TextField
            fullWidth
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            margin="normal"
            multiline
            rows={4}
            required
            autoComplete="off"
          />
          <TextField
            fullWidth
            label="Persona"
            value={persona}
            onChange={(e) => setPersona(e.target.value)}
            margin="normal"
            multiline
            rows={4}
            required
            autoComplete="off"
          />
          <TextField
            fullWidth
            label="Scenario"
            value={scenario}
            onChange={(e) => setScenario(e.target.value)}
            margin="normal"
            multiline
            rows={4}
            required
            autoComplete="off"
          />
          <TextField
            fullWidth
            label="First Message"
            value={firstMessage}
            onChange={(e) => setFirstMessage(e.target.value)}
            margin="normal"
            required
            autoComplete="off"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Tags</InputLabel>
            <Select
              multiple
              value={tags}
              onChange={handleTagChange}
              label="Tags"
              renderValue={(selected) => Array.isArray(selected) ? selected.join(', ') : ''}
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Original">Original</MenuItem>
              <MenuItem value="Fandom Character">Fandom Character</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Visibility</InputLabel>
            <Select
              value={visibility}
              onChange={(e) => setVisibility(e.target.value)}
              label="Visibility"
            >
              <MenuItem value="public">Public</MenuItem>
              <MenuItem value="private">Private</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => setShowImageInput(!showImageInput)}
            style={{ margin: '16px 0' }}
          >
            {showImageInput ? 'Hide Image Option' : 'Edit image'}
          </Button>
          {showImageInput && (
            <>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: 'block', margin: '16px 0' }}
              />
              <Button
                variant="outlined"
                color="error"
                fullWidth
                onClick={handleDeleteImage}
                style={{ margin: '16px 0' }}
              >
                Delete Image
              </Button>
            </>
          )}
          {error && <Typography color="error">{error}</Typography>}
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Update Character
            </Button>
            <Button variant="contained" color="secondary" onClick={handleCancel} fullWidth style={{ marginLeft: '8px' }}>
              Cancel Editing
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
    </ThemeProvider>
  );
};

export default EditCharacter;