//services/characterService.js

import axios from 'axios';
import { API_URL } from '../config.js';

const headers = {
  'ngrok-skip-browser-warning': 'any_value'
};

export const createCharacter = (characterData) => {
  const token = localStorage.getItem('token');
  return axios.post(`${API_URL}/characters`, characterData, {
    headers: { ...headers, Authorization: token, 'Content-Type': 'multipart/form-data' },
  });
};

export const getPublicCharacters = () => axios.get(`${API_URL}/characters/public`, { headers });

export const getCharacter = (id) => {
  return axios.get(`${API_URL}/characters/${id}`, { headers }).catch((error) => {
    console.error('Error fetching character:', error);
    throw error;
  });
};

export const getUserCharacters = () => {
  const token = localStorage.getItem('token');
  return axios.get(`${API_URL}/characters/user`, {
    headers: { ...headers, Authorization: token },
  });
};

export const deleteCharacter = (id) => {
  const token = localStorage.getItem('token');
  return axios.delete(`${API_URL}/characters/${id}`, {
    headers: { ...headers, Authorization: token },
  });
};

export const updateCharacter = (id, characterData) => {
  const token = localStorage.getItem('token');
  return axios.put(`${API_URL}/characters/${id}`, characterData, {
    headers: { ...headers, Authorization: token, 'Content-Type': 'multipart/form-data' },
  }).catch((error) => {
    console.error('Error updating character:', error);
    throw error;
  });
};