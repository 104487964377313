// remarkInlineQuote.js
import { visit } from 'unist-util-visit';

export default function remarkInlineQuote() {
  return function transformer(tree) {
    visit(tree, 'text', (node, index, parent) => {
      if (parent.type === 'paragraph') {
        const text = node.value;
        const parts = text.split(/(".*?")/);
        const nodes = parts.map((part, i) => {
          if (part.startsWith('"') && part.endsWith('"')) {
            return {
              type: 'inlineQuote',
              value: part.slice(1, -1),
            };
          }
          return {
            type: 'text',
            value: part,
          };
        });
        parent.children.splice(index, 1, ...nodes);
      }
    });
  };
}