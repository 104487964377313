// pages/Explore.js
// pages/Explore.js
import React, { useEffect, useState } from 'react';
import { getPublicCharacters as characterServiceGetPublic } from '../services/characterService';
import { Link } from 'react-router-dom';
import { Container, Typography, Box, Grid, Card, CardContent, CardMedia, Button, TextField, InputAdornment, Select, MenuItem, FormControl, InputLabel, ThemeProvider, CssBaseline, Pagination } from '@mui/material';
import { Helmet } from 'react-helmet';
import { API_URL } from '../config.js';
import WithAds from './WithAds';
import SearchIcon from '@mui/icons-material/Search';
import { lightTheme, darkTheme } from '../theme';

const Explore = ({ darkMode }) => {
  const [characters, setCharacters] = useState([]);
  const [filteredCharacters, setFilteredCharacters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [imageUrls, setImageUrls] = useState({});
  const [chatCounts, setChatCounts] = useState({});
  const [selectedTags, setSelectedTags] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pagination = 50;

  useEffect(() => {
    const fetchCharacters = async () => {
      try {
        const response = await characterServiceGetPublic();
        setCharacters(response.data);
        setFilteredCharacters(response.data);
      } catch (err) {
        setError("Failed to load characters. Please try logging out and logging back in.");
      } finally {
        setLoading(false);
      }
    };
    fetchCharacters();
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      const urls = {};
      for (const character of characters) {
        if (character.imageUrl) {
          const imageUrl = await fetchImageUrl(character.imageUrl);
          urls[character._id] = imageUrl;
        }
      }
      setImageUrls(urls);
    };
    fetchImages();
  }, [characters]);

  useEffect(() => {
    const fetchChatCounts = async () => {
      try {
        const response = await fetch(`${API_URL}/character/chat-counts`);
        const data = await response.json();
        setChatCounts(data);
      } catch (err) {
        console.error('Error fetching chat counts:', err);
      }
    };
    fetchChatCounts();
  }, [characters]);

  useEffect(() => {
    let filtered = characters.filter((character) =>
      character.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (selectedTags.length === 0 || selectedTags.every((tag) => character.tags.includes(tag)))
    );
    filtered.sort((a, b) => (chatCounts[b._id] || 0) - (chatCounts[a._id] || 0));
    setFilteredCharacters(filtered);
    setTotalPages(Math.ceil(filtered.length / pagination));
  }, [searchQuery, selectedTags, characters, chatCounts]);

  const fetchImageUrl = async (imageUrl) => {
    try {
      const response = await fetch(`${API_URL}${imageUrl}`, {
        headers: {
          'ngrok-skip-browser-warning': 'any_value'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch image');
      }
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    } catch (err) {
      console.error('Error fetching image:', err);
      return null;
    }
  };

  const handleTagChange = (e) => {
    setSelectedTags(e.target.value);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  const startIndex = (page - 1) * pagination;
  const endIndex = startIndex + pagination;
  const charactersToDisplay = filteredCharacters.slice(startIndex, endIndex);

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Helmet>
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5234640600425235" crossOrigin="anonymous"></script>
        </Helmet>
        <Box mt={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            Explore Characters
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search characters..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ marginBottom: '16px' }}
            autoComplete="off"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Filter by Tags</InputLabel>
            <Select
              multiple
              value={selectedTags}
              onChange={handleTagChange}
              label="Filter by Tags"
              renderValue={(selected) => selected.join(', ')}
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Original">Original</MenuItem>
              <MenuItem value="Fandom Character">Fandom Character</MenuItem>
            </Select>
          </FormControl>
          <Grid container spacing={3}>
            {charactersToDisplay.map((character) => (
              <Grid item xs={12} sm={6} md={4} key={character._id}>
                <Card
                  sx={{
                    boxShadow: 3,
                    borderRadius: 2,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    transition: 'transform 0.3s',
                    '&:hover': {
                      transform: 'scale(1.05)',
                    },
                  }}
                >
                  {character.imageUrl && (
                    <CardMedia
                      component="img"
                      height="194"
                      image={imageUrls[character._id] || ''}
                      alt={character.name}
                      sx={{ borderRadius: '8px 8px 0 0' }}
                    />
                  )}
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h5" component="div">
                      {character.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {character.description}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      💬 {chatCounts[character._id] || 0}
                    </Typography>
                  </CardContent>
                  <Button
                    component={Link}
                    to={`/chat/${character._id}`}
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ marginTop: '16px' }}
                  >
                    Chat
                  </Button>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
          />
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default WithAds(Explore);