// components/ForgotPasswordDialog.js
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from '@mui/material';

const ForgotPasswordDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Forgot Password</DialogTitle>
      <DialogContent>
        <Typography>
          We apologize, but the automatic password reset feature is not available at this time.
        </Typography>
        <Typography>
          Please open a ticket on our Discord server to reset your password:
        </Typography>
        <Typography>
          <a href="https://discord.gg/tyWu7V4Szk" target="_blank" rel="noopener noreferrer">
            https://discord.gg/tyWu7V4Szk
          </a>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ForgotPasswordDialog;