// pages/Home.js
import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import WithAds from './WithAds';
import Sidebar from '../components/Sidebar';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
'@global': {
    body: {
        //background: 'linear-gradient(135deg, #243949 0%, #517fa4 100%)',
        background: 'linear-gradient(135deg, #cfd8e8 0%, #243949 100%)',//517fa4 243949
        color: '#fff',
        //margin: 0,
        //padding: 0,
        height: '100vh',
        //display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        },
        html: {
            height: '100%',
        },
    },

  container: {
    textAlign: 'center',
    padding: '12%', // Fixed pixel value
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  animatedText: {
    animation: '$fadeIn 2s ease-in-out',
  },
  '@keyframes fadeIn': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
  button: {
    marginTop: '32px', // Fixed pixel value
    animation: '$slideIn 1.5s ease-in-out',
  },
  '@keyframes slideIn': {
    '0%': { transform: 'translateY(50px)', opacity: 0 },
    '100%': { transform: 'translateY(0)', opacity: 1 },
  },
}));

const Home = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="md" className={classes.container}>
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5234640600425235"
          crossOrigin="anonymous"
        ></script>
      </Helmet>
      <Box>
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          color="white"
          className={classes.animatedText}
        >
          Welcome to Fichat
        </Typography>
        <Box marginBottom={4}>
          <Typography variant="body1" paragraph className={classes.animatedText}
          color="white">
            Create and interact with AI characters.
          </Typography>
        </Box>
        <Button
          component={Link}
          to="/explore"
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
        >
          Explore Characters
        </Button>
      </Box>
    </Container>
  );
};

export default WithAds(Home);