// services/authService.js

import axios from 'axios';
import { API_URL } from '../config.js';

const headers = {
  'ngrok-skip-browser-warning': 'any_value'
};

export const login = (credentials) => axios.post(`${API_URL}/login`, credentials, { headers });
export const signup = (userData) => axios.post(`${API_URL}/signup`, userData, { headers });
export const deleteUserAccount = () => {
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');
  return axios.delete(`${API_URL}/users/${userId}`, {
    headers: { ...headers, Authorization: token },
  });
};
export const checkUsernameAvailability = (username) =>
  axios.get(`${API_URL}/check-username/${username}`, { headers }).then(response => response.data.available);
export const checkEmailAvailability = (email) =>
  axios.get(`${API_URL}/check-email/${email}`, { headers }).then(response => response.data.available);
export const updateUserPersona = (personaData) => {
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');
  return axios.put(`${API_URL}/users/${userId}`, personaData, {
    headers: { ...headers, Authorization: token },
  });
};
export const getUserPersona = () => {
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');
  return axios.get(`${API_URL}/users/${userId}`, {
    headers: { ...headers, Authorization: token },
  });
};

export const updateUserCredentials = (credentials) => {
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');
  return axios.put(`${API_URL}/users/${userId}/update-credentials`, credentials, {
    headers: { ...headers, Authorization: token },
  });
};